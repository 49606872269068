<template>
  <section>
    <v-sheet
      height="auto"
      width="auto"
      class="mx-3 mb-3 white--text"
      color="rgba(255,255,255,0.1)"
    >
      <v-row class="justify-center">
        <v-col cols="12">
          <div class="text-h4">"のなかや"のモーニング</div>
          <!-- <div class="mt-5 text-caption">火曜日から土曜日の7:00-9:00</div> -->
          <div>
            <a
              href="https://www.instagram.com/nonakaya5"
              class="btn-gradient-radius"
              target="top"
              >@nonakaya5</a
            >
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      style="float: clear"
      height="auto"
      width="auto"
      class="mx-3 mb-3 white--text"
      color="rgba(255,255,255,0.1)"
    >
      <div class="text-left" style="padding: 20px">
        <ul>
          <li>ドリンクのみテイクアウトのご利用が可能です</li>
          <li class="pb-2">
            <a href="http://numazu-j.net/kuranoue.html">魚町蔵ノ上</a
            >ご宿泊特典の「Welcome Drink Ticket」でもご利用いただけます
          </li>
        </ul>
      </div>
    </v-sheet>
  </section>
</template>

<script>
// import TheChamberMenu from '@/components/TheChamberMenu.vue'
export default {
  // components: {
  //   TheChamberMenu,
  // },
  // data() {
  //   return {
  //     menuList: [
  //       ['Coffee (Hot/Ice)', 'コーヒー', '300'],
  //       ['Chai (Hot/Ice)', 'チャイ', '400'],
  //       ['Toast Set', 'トーストセット', '500'],
  //       ['Hot Tea', '和紅茶', '300'],
  //       ['Orange', 'オレンジジュース', '350'],
  //       ['Grape Fruit', 'グレープフルーツジュース', '350'],
  //       ['Oolong Tea', 'ウーロン茶', '300'],
  //       ['Cola', 'コーラ', '300'],
  //       ['Ginger Ale', 'ジンジャエール', '300'],
  //       ['Fever Tree Tonic Water', 'FeverTreeトニックウォーター', '350'],
  //     ],
  //   }
  // },
}
</script>
<style scoped>
.strong {
  font-weight: 600;
}
.btn-gradient-radius {
  display: inline-block;
  padding: 1px 5px;
  height: 20px;
  border-radius: 10px;
  font-size: 0.7rem;
  text-decoration: none;
  color: #fff;
  background-image: linear-gradient(45deg, #b49403 0%, #ff6a5f 100%);
  transition: 0.4s;
  margin-bottom: 30px;
  /* width:100px; */
}
.btn-gradient-radius:hover {
  text-decoration: none;
  background-image: linear-gradient(45deg, #ffa14a 0%, #ff5fff 100%);
  color:#fff;
}
</style>
