<template>
  <section>
    <v-sheet
      color="rgba(255,255,255,0.85)"
      class="onImg black--text mb-3 mx-3 py-10 elevation-5"
    >
      <div>Instagramでもご案内しています</div>
      <a
        href="https://www.instagram.com/the_chamber_numazu/"
        class="btn-gradient-radius"
        target="top"
        >@the_chamber_numazu</a
      >

      <v-row class="justify-sm-center pt-10">
        <v-col v-for="shop in shops" :key="shop.name" cols="6" sm="4" md="2">
          <div
            class="trim-image"
            :style="'background-image:url(' + imgSrc(shop[5]) + ')'"
          >
            <div class="week_icon">{{ shop[1] }}</div>
          </div>
          <span class="shop">【{{ shop[2] }}】</span>
          <div class="shop_menu">{{ shop[3] }}</div>
          <a
            :href="'https://www.instagram.com/' + shop[4]"
            class="btn-gradient-radius"
            target="top"
            >@{{ shop[4] }}</a
          >
        </v-col>
      </v-row>
      <!-- <hr class="d-md-none hr-grey" /> -->
      <!-- <div class="vhr d-none d-md-block"/> -->
    </v-sheet>

    <v-sheet
      height="auto"
      width="auto"
      class="mx-3 mb-3 white--text"
      color="rgba(255,255,255,0.1)"
    >
      <div class="text-left" style="padding: 20px">
        <ul>
          <li>
            ランチタイムは各店舗のメニューのご提供となります。バータイムとはメニューが異なり、酒類の提供有無は店舗ごとに異なります。予めご了承ください。
          </li>
          <li>ご予約、メニュー詳細につきましては各店舗にお問合せください</li>
          <li>都合により急遽お休みさせていただく場合もございます</li>
          <li>
            最新情報はTHE CHAMBERまたは各出店店舗のInstagramをご覧ください
          </li>
        </ul>
      </div>
    </v-sheet>
  </section>
</template>

<script>
export default {
  data() {
    return {
      shops: [
        // [1, '月', 'トレス・エルマノス', 'Tacos/タコス', 'tacos_3hermanos_df', 'tacos.jpg'],
        [1, '月', '定休日', 'Closed', '', 'dish.png'],
        [
          1,
          '火',
          'TESHIO DELI',
          'Bowl/丼',
          'teshio_deli_numazu',
          'teshio_deli_numazu.png',
        ],
        [1, '水', '準備中', 'Coming soon...', '', 'dish.png'],
        // [
        //   1,
        //   '水',
        //   'Town in Town 315',
        //   'Thai food/タイ料理',
        //   'townintown315',
        //   'town_in_town.png',
        // ],
        // [1, '水', '準備中', 'Coming Soon', '', 'dish.png'],
        [
          1,
          '木',
          'とんめし',
          'Cuban Sand/キューバサンド',
          'ton_meshi',
          'ton_meshi.png',
        ],
        // [1, '金', '準備中', 'Coming Soon', '', 'dish.png'],
        [
          1,
          '金',
          'PAKU PAKU',
          'Noodles/沖縄そば',
          'pakupaku_ok',
          'pakupaku.jpg',
        ],
      ],
    }
  },
  methods: {
    imgSrc(str) {
      return require('@/assets/img/thechamber/' + str)
    },
  },
}
</script>
<style scoped>
.btn-gradient-radius {
  display: inline-block;
  padding: 1px 5px;
  height: 20px;
  border-radius: 10px;
  font-size: 0.7rem;
  text-decoration: none;
  color: #fff;
  background-image: linear-gradient(45deg, #b49403 0%, #ff6a5f 100%);
  transition: 0.4s;
  margin-bottom: 30px;
  /* width:100px; */
}
.btn-gradient-radius:hover {
  text-decoration: none;
  background-image: linear-gradient(45deg, #ffa14a 0%, #ff5fff 100%);
  color:#fff;
}
div.trim-image {
  margin: 0px auto;
  text-align: left;
  background-size: cover;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-position: left top;
  border: 1px solid #fff;
}
.week_icon {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: 1.2rem;
  font-weight: 800;
  color: #333;
  background-color: #fefefe;
  border: 1px solid #eee;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 10;
  left: 0px;
  margin: -10px;
}
span.shop {
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin-top: 10px;
  display: block;
}
.shop_menu {
  font-size: 0.7rem;
  line-height: 0.7rem;
  /* font-weight: 100; */
  /* margin-bottom:20px; */
}
</style>
